import {Box, Button, Grid, Pagination, Skeleton, Typography, Paper, TextField, CircularProgress, Tabs, Tab} from "@mui/material";
import "./CreatorSummary.scss";
import {downloadCSV,downloadCSVEP, getPaymentSummary, getProgress, submitPayments,getEarlyPaymentSummary,getEarlyPaymentProgress} from "../services/CreatorSummaryService";
import React, {useContext, useEffect, useRef, useState,ReactNode} from "react";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {CurrentReportDate} from "../hooks/useReportDate";
import {PaymentSummary,EarlyPaymentProgress} from "../model/PaymentSummary";
import {AxiosResponse} from "axios";
import AdditionalPaymentDialog from "./AdditionalPaymentDialog/AdditionalPaymentDialog";
import {AdditionalPayment} from "../model/AdditionalPayment";
import CreatorSummaryCard from "./CreatorSummaryCard";
import CreatorSummaryItemWrapper from "./CreatorSummaryItemWrapper";
import EarlyPayments from './EarlyPayments/CreatorSummaryItemWrapper'
import { getLockedYTPayment, lockYTPayment} from "../services/LockService";
import {LockedPayment} from "../model/LockedPayment";
import { PaymentsReq } from "../model/PaymentsReq";
import {CurrentMainSnackbar} from "../hooks/useMainSnackbar";
import { RefreshOutlined, SyncAltOutlined } from "@mui/icons-material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { YTSummary } from "../model/YTSummary";


function a11yProps(index: number) {
    return {
        id: `gam-tab-${index}`,
        'aria-controls': `gam-tabdetails-${index}`,
    };
}


const CreatorSummary = () => {
    const {year, month} = useContext(CurrentReportDate);
    const [paymentSummaries, setPaymentSummaries] = useState<PaymentSummary[]>([]);
    const [earlyPayentSummaries, setEarlyPaymentSummaries] = useState<PaymentSummary[]>([]);
    const [open, setOpen] = useState(false);
    const [kpeId, setKPEId] = useState('');
    const [creatorId, setCreatorId] = useState('');
    const [creatorName, setCreatorName] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage] = useState(100);
    const [loading, setLoading] = useState(true);
    const [earlyPaymentsLoading, setEarlyPaymentsLoading] = useState(true);
    const [YTProgressLoading, setYTProgressLoading] = useState(false);
    const [loadingPayments, setLoadingPayments] = useState(false);
    const [paymentsLocked, setPaymentsLocked] = useState(false);
    const [idsToExport, setIdsToExport] = useState<string[]>([]);
    const {updateMainSnackbar} = useContext(CurrentMainSnackbar);
    const [checklist, setChecklist] = useState<YTSummary | null>(null);
    const [tabValue, setTabValue] = useState(0);
    const [tabComponent, setTabComponent] = useState<ReactNode>();
    const isPaymentApiCalled = useRef<boolean>(false)
    const isEearlyPaymentApiCalled = useRef<boolean>(false)
    const yearRef = useRef(year);
    const monthRef = useRef(month);

     const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const refreshData = () => {
        setLoading(true);
        getPaymentSummary(year, month, '')
            .then((res: AxiosResponse<PaymentSummary[]>) => {
                if(res.data[0]?.month !== monthRef.current || res.data[0]?.year !== yearRef.current) {
                    setLoading(false)
                    return;
                }                
                for(let item in res.data) {
                    res.data[item].id = res.data[item].crmCreator.creatorId;
                }
                setPaymentSummaries(res.data)
                setCurrentPage(1);
                getLockedYTPayment(year, month)
                    .then((res: AxiosResponse<LockedPayment>) => {
                        if (res.status === 200) {
                            setPaymentsLocked(res.data.locked);
                            setLoading(false);
                        }
                    });
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
        
    }

    const refreshEarlyPaymentData = () => {
        setEarlyPaymentsLoading(true);
        getEarlyPaymentSummary(year, month)
            .then((res: AxiosResponse<PaymentSummary[]>) => {
                if(res.data[0]?.month !== month || res.data[0]?.year !== year) return;
                for(let item in res.data) {
                    res.data[item].id = res.data[item].crmCreator.creatorId;
                }
                setEarlyPaymentSummaries(res.data)
                setEarlyPaymentsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setEarlyPaymentsLoading(false);
            });
    }

    const getStatusIcon = (status: boolean | undefined): ReactNode => {
        if (status === undefined) {
            return <ErrorOutlineIcon sx={{color: 'red'}}/>;
        }
        return status ? <CheckCircleOutlineIcon sx={{color: 'green'}}/> : <ErrorOutlineIcon sx={{color: 'red'}}/>;
    }
    const ProcessIcon = (isProcessed:boolean)=>{
        return [getStatusIcon(isProcessed),isProcessed?"Processed":"Failed"]
     }

    const getProessedStatus = (flags: YTSummary | null): boolean => {
        if (flags === null) return false;

        return flags.channelState === 2 && 
               flags.affiliateTaxSummaryState === 2 &&
               flags.ecommerceState === 2 &&
               flags.assetSummaryState === 2 &&
               flags.redRawMusicState === 2 &&
               flags.redRawVideoState === 2 &&
               flags.videoSummaryState === 2 &&
               flags.shortsSubsState === 2 &&
               flags.claimsState === 2 &&
               flags.shortsAdsState === 2 &&
               flags.crmContractsStatus === 2 && 
               flags.calculationsStatus === 2;
    }

    const getProcessingStatus = (flags: YTSummary | null): boolean => {
        if (flags === null) return false;

        return flags.channelState === 1 || 
               flags.affiliateTaxSummaryState === 1 ||
               flags.ecommerceState === 1 ||
               flags.assetSummaryState === 1 ||
               flags.redRawMusicState === 1 ||
               flags.redRawVideoState === 1 ||
               flags.videoSummaryState === 1 ||
               flags.shortsSubsState === 1 ||
               flags.claimsState === 1 ||
               flags.shortsAdsState === 1 || 
               flags.crmContractsStatus === 1 || 
               flags.calculationsStatus === 1;
    }

    const getNotStartedStatus = (flags: YTSummary | null): boolean => {
        if (flags === null) return true;

        return flags.channelState === 0 && 
               flags.affiliateTaxSummaryState === 0 &&
               flags.ecommerceState === 0 &&
               flags.assetSummaryState === 0 &&
               flags.redRawMusicState === 0 &&
               flags.redRawVideoState === 0 &&
               flags.videoSummaryState === 0 &&
               flags.shortsSubsState === 0 &&
               flags.claimsState === 0 &&
               flags.shortsAdsState === 0 && 
               flags.crmContractsStatus === 0 && 
               flags.calculationsStatus === 0;
    }

    const getNotFiledStatus = (flags: YTSummary | null): boolean => {
        return flags?.affiliateTaxSummaryState !==3 &&
               flags?.ecommerceState !==3 &&
               flags?.assetSummaryState !==3 &&
               flags?.redRawMusicState !==3 &&
               flags?.redRawVideoState !==3 &&
               flags?.videoSummaryState !==3 &&
               flags?.shortsSubsState !==3 &&
               flags?.claimsState !==3 &&
               flags?.shortsAdsState !==3 &&
               flags?.channelState !==3 &&
               flags?.crmContractsStatus !==3 &&
               flags?.calculationsStatus !==3;
    }

    const getFailedStatus = (flags: YTSummary | null): boolean => {
        if (flags === null) return false;

        return flags.channelState === 3 || 
               flags.affiliateTaxSummaryState === 3 ||
               flags.ecommerceState === 3 ||
               flags.assetSummaryState === 3 ||
               flags.redRawMusicState === 3 ||
               flags.redRawVideoState === 3 ||
               flags.videoSummaryState === 3 ||
               flags.shortsSubsState === 3 ||
               flags.claimsState === 3 ||
               flags.shortsAdsState === 3 ||
               flags.crmContractsStatus === 3 ||
               flags.calculationsStatus === 3;
    }

    const getPartialStatus = (flags: YTSummary | null): boolean => {
        const states = [
            flags?.affiliateTaxSummaryState,
            flags?.assetSummaryState,
            flags?.ecommerceState,
            flags?.redRawMusicState,
            flags?.redRawVideoState,
            flags?.videoSummaryState,
            flags?.claimsState,
            flags?.shortsAdsState,
            flags?.shortsSubsState,
            flags?.channelState,
            flags?.crmContractsStatus,
            flags?.calculationsStatus
                      ];

            const allStates = (value: number | undefined) => states.every((state) => state === value);
            const anyState = (value: number | undefined) => states.some((state) => state === value);

            switch (true) {
                    case flags === null || allStates(0):
                        return false;
                    case anyState(1):
                        return false;
                    case allStates(2):
                        return false;
                    case anyState(3):
                        return false;
                    case flags?.calculationsStatus === 1:
                        return false;
                    default:
                       return true;
                    }
    }

    const isExportable = (flags: YTSummary | null): boolean => {
        if (flags === null) return false;
        
        if(getPartialStatus(flags) || getProessedStatus(flags)) return false

        return true;
    }

    // const initialRender = useRef(true);
    useEffect(() => { 
        yearRef.current = year;
        monthRef.current = month; 
    },[month,year])

    useEffect(() => {
        // if (initialRender.current) {
            //     initialRender.current = false;
            //     return;
            // }
            setChecklist(null);
            setPaymentSummaries([]);
            setEarlyPaymentSummaries([]);
            setYTProgressLoading(true);
            setLoading(true);
            setEarlyPaymentsLoading(true);
            isPaymentApiCalled.current = false
            isEearlyPaymentApiCalled.current = false
            const intervalId = setInterval(() => {
            if(isPaymentApiCalled.current === false){
            isPaymentApiCalled.current = true
            getProgress(year, month).then((res: AxiosResponse<YTSummary>) => {
            setYTProgressLoading(false);
            if(res.data) setChecklist(res.data);
            else setChecklist(null);
                    
            if(res.data && getNotStartedStatus(res.data)){
                clearInterval(intervalId);
                setLoading(false);
            }

            else if(res.data && getProessedStatus(res.data)){
                clearInterval(intervalId);
                refreshData();
            }
            else if (res.data && getFailedStatus(res.data)) {
              clearInterval(intervalId);
                setLoading(false);
            }
            else if (res.data && getPartialStatus(res.data)) {
                clearInterval(intervalId);
                refreshData();
            }
            else setLoading(false);
          }).finally(()=>  isPaymentApiCalled.current = false) 
           }
        }, 2000)

            const earlyPaymentIntervalId = setInterval(() => {
                if(isEearlyPaymentApiCalled.current === false) { 
                isEearlyPaymentApiCalled.current = true;
                getEarlyPaymentProgress(year, month).then((res: AxiosResponse<EarlyPaymentProgress>) => {
                    
                    if(res.data && 
                       res.data.calculationStatus === 0 &&
                       res.data.crmStatus === 0 &&
                       res.data.edfStatus === 0){
                        clearInterval(earlyPaymentIntervalId);
                        setEarlyPaymentsLoading(false);
                    }
    
                    else if(res.data && 
                        res.data.calculationStatus === 2 &&
                        res.data.crmStatus === 2 &&
                        res.data.edfStatus === 2){
                        clearInterval(earlyPaymentIntervalId);
                        refreshEarlyPaymentData();
                    }
                    else if (res.data && 
                        (res.data.calculationStatus === 3 || 
                        res.data.crmStatus === 3 ||
                        res.data.edfStatus === 3)) {
                        clearInterval(earlyPaymentIntervalId);
                        setEarlyPaymentsLoading(false);
                    }
                    else setEarlyPaymentsLoading(false);
                  }).finally(() => isEearlyPaymentApiCalled.current = false) 
                 }
                }
            , 2000)

        return () => {
          clearInterval(intervalId);
          clearInterval(earlyPaymentIntervalId);
        };
      }, [year, month]);

    const openManualAdjustment = (kpeId: string, creatorId: string, creatorName: string) => {
        setOpen(true);
        setKPEId(kpeId);
        setCreatorId(creatorId);
        setCreatorName(creatorName);
    }
    const handleClose = () => {
        setOpen(false);
        setKPEId('');
        setCreatorId('');
        setCreatorName('');
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const deleteAdditionalPayment = (id: string) => {
        tabValue === 0 ?
        setPaymentSummaries(paymentSummaries.map((ps) => {
            ps.totalEarnings -= ps.additionalPayments.find((ap) => ap.id === id)?.amount || 0;
            ps.additionalPayments = ps.additionalPayments.filter((ap) => ap.id !== id)
            return ps;
        })):
        setEarlyPaymentSummaries(earlyPayentSummaries.map((ps) => {
            ps.totalEarnings -= ps.additionalPayments.find((ap) => ap.id === id)?.amount || 0;
            ps.additionalPayments = ps.additionalPayments.filter((ap) => ap.id !== id)
            return ps;
        }));
    };

    const updateAdditionalPayment = (ap: AdditionalPayment) => {
        tabValue === 0 ?
        setPaymentSummaries(paymentSummaries.map((ps) => {
            if (ps.channelPaymentSummaries.findIndex((cps) => cps.channelKpeId === ap.channelKpeId) > -1) {
                ps.additionalPayments = [...ps.additionalPayments, ap];
                ps.totalEarnings += ap.amount;
            }
            return ps;
        })):
        setEarlyPaymentSummaries(earlyPayentSummaries.map((ps) => {
            if (ps.channelPaymentSummaries.findIndex((cps) => cps.channelKpeId === ap.channelKpeId) > -1) {
                ps.additionalPayments = [...ps.additionalPayments, ap];
                ps.totalEarnings += ap.amount;
            }
            return ps;
        }));
    }

    const lockUpPayment = () => {
        setLoading(true);
        lockYTPayment(year, month)
            .then(() => {
                getLockedYTPayment(year, month)
                    .then((res: AxiosResponse<LockedPayment>) => {
                        if (res.status === 200) {
                            setPaymentsLocked(res.data.locked);
                            setLoading(false);
                        }
                    });
            });
    }

    const exportData = () => {
        setLoading(true);
        const idsToExportString = idsToExport.length > 0 ? idsToExport.join(','): '';
        
        downloadCSV(year, month, 'creator',idsToExportString).then((response) => {
            setLoading(false);
            downloadFile(response.data);
        })
    }

    const exportEarlyPaymentData = () => {
        setEarlyPaymentsLoading(true);
        
        downloadCSVEP(year, month,'').then((response) => {
            setEarlyPaymentsLoading(false);
            downloadFile(response.data);
        })
    }

    const downloadFile = (file: Blob | MediaSource) => {
        const href = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `summary-${year}-${month}.csv`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    const onSubmitPayments = () => {

        setLoadingPayments(true);
        const obj = dataFromChild ? JSON.parse(dataFromChild) : {};
        const creators: Array<string> = Object.keys(obj).filter((key) => obj[key]);
        let data: PaymentsReq = {
            "year": year,
            "month": month,
            "batchName":`creator-payment-${year}-${month}`,
            "ids": creators
        };
        submitPayments(data)
            .then((res) => {
                updateMainSnackbar(true, "success", "Selected payments have been sent");
                setDataFromChild('');
            })
            .finally(() => setLoadingPayments(false))
    }

    const [dataFromChild, setDataFromChild] = useState<string | null>(null);
    const receiveDataFromChild = (dataRef: React.MutableRefObject<string | null>) => {
        setDataFromChild(dataRef.current);
    };

    const [searchTerm, setSearchTerm] = useState("");
    const handleSearchChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSearchTerm(event.target.value);
    };

    const filteredData = paymentSummaries.filter((row) => {

        const searchTermLower = searchTerm.toLowerCase();
        const creator = row.crmCreator;// Get creator details

        // Check firstName, lastName, and fullName
        const firstNameMatch = creator.firstName ? creator.firstName.toLowerCase().includes(searchTermLower) : false;
        const lastNameMatch = creator.lastName ? creator.lastName.toLowerCase().includes(searchTermLower) : false;
        const fullNameMatch = creator.fullName ? creator.fullName.toLowerCase().includes(searchTermLower) : false;

        // Concat all channelName and channelKpeId from channelPaymentSummaries of the row
        let channelNames = row.channelPaymentSummaries.map((cps) => `${cps.channelName}${cps.channelKpeId}`).join(' ');
        const channelNamesMatch = channelNames.toLowerCase().includes(searchTermLower);

    // Return true if any of the criteria match
    return firstNameMatch || lastNameMatch || fullNameMatch || channelNamesMatch;

    }
    );
    
    const EarlyPaymentfilteredData = earlyPayentSummaries.filter((row) => {
            
            const searchTermLower = searchTerm.toLowerCase();
            const creator = row.crmCreator;// Get creator details
    
            // Check firstName, lastName, and fullName
            const firstNameMatch = creator.firstName ? creator.firstName.toLowerCase().includes(searchTermLower) : false;
            const lastNameMatch = creator.lastName ? creator.lastName.toLowerCase().includes(searchTermLower) : false;
            const fullNameMatch = creator.fullName ? creator.fullName.toLowerCase().includes(searchTermLower) : false;
    
            // Concat all channelName and channelKpeId from channelPaymentSummaries of the row
            let channelNames = row.channelPaymentSummaries.map((cps) => `${cps.channelName}${cps.channelKpeId}`).join(' ');
            const channelNamesMatch = channelNames.toLowerCase().includes(searchTermLower);
       
        // Return true if any of the criteria match
        return firstNameMatch || lastNameMatch || fullNameMatch || channelNamesMatch;
    
});

    useEffect(() => {
        let component = <></>;
        switch (tabValue) {
            case 0:
                component =  <>
                 <Box sx={{width: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 4}}>
                    <CreatorSummaryCard
                        title={'Gross YT'}
                        total={getProessedStatus(checklist) || getPartialStatus(checklist) ? 
                               paymentSummaries.reduce((acc, cur) => acc + cur.totalYTGrossAcrossChannels, 0) : 0}
                        loading={loading}
                    />
                    <CreatorSummaryCard
                        title={'Net YT'}
                        total={getProessedStatus(checklist) || getPartialStatus(checklist)  ?  
                               paymentSummaries.reduce((acc, cur) => acc + cur.totalYTNetAcrossChannels, 0) : 0}
                        loading={loading}
                    />
                    <CreatorSummaryCard
                        title={'Gross Paid Media'}
                        total={getProessedStatus(checklist) || getPartialStatus(checklist)  ? 
                               paymentSummaries.reduce((acc, cur) => acc + cur.totalGamGrossAcrossChannels, 0) : 0}
                        loading={loading}
                    />
                    <CreatorSummaryCard
                        title={'Net Paid Media'}
                        total={getProessedStatus(checklist) || getPartialStatus(checklist)  ? 
                               paymentSummaries.reduce((acc, cur) => acc + cur.totalGamNetAcrossChannels, 0) : 0}
                        loading={loading}
                    />
                </Box>
                <Box sx={{
                    width: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    {loading ? <Skeleton sx={{width: 1, height: 400}} variant={"rectangular"}/> :
                        <>
                          <CreatorSummaryItemWrapper
                                paymentSummaries={filteredData}
                                currentPage={currentPage}
                                recordsPerPage={recordsPerPage}
                                loading={loading}
                                creatorSummary={true}
                                openManualAdjustment={openManualAdjustment}
                                deleteAdditionalPayment={deleteAdditionalPayment}
                                locked={paymentsLocked}
                                sendDataToParent={receiveDataFromChild}
                                idsToExport={idsToExport}
                                setIdsToExport={setIdsToExport}
                                searchedTerm={searchTerm}
                            />
                        </>}
                </Box>
                </>
                break;
            case 1:
                component = <>
                {earlyPaymentsLoading ? <Skeleton sx={{width: 1, height: 400}} variant={"rectangular"}/> :
                <EarlyPayments
                 paymentSummaries={EarlyPaymentfilteredData}
                 currentPage={currentPage}
                 recordsPerPage={recordsPerPage}
                 loading={earlyPaymentsLoading}
                 creatorSummary={true}
                 openManualAdjustment={openManualAdjustment}
                 deleteAdditionalPayment={deleteAdditionalPayment}
                 locked={false}
                 sendDataToParent={receiveDataFromChild}
                 idsToExport={idsToExport}
                 setIdsToExport={setIdsToExport}
                 searchedTerm={searchTerm}
                />}
                </>
                break; 
        }
        setTabComponent(component);
    }, [tabValue,searchTerm,loading,earlyPaymentsLoading,paymentSummaries,earlyPayentSummaries]);

    return (
        <Box className={'youtube-summary-container'}>
            <Paper elevation={1} sx={{width: '90%',height:'85vh', py: 3, px: 4}}>
            <div style={{display:'flex'}}>
            <div style={{flex:0.3,flexDirection:'row'}}>
                        <Box sx={{display: 'flex', alignItems: 'center',pb:2}}>
                            {(loading || YTProgressLoading) && <CircularProgress size={20} sx={{ mr: 2 }} />}
                            <Typography variant={'h4'} noWrap className={'h4-custom'} sx={{mr:2,display:'inline'}}>
                                Creator summary
                            </Typography>
                        {tabValue === 0 && 
                        <> 
                            {checklist !== null && 
                            getProcessingStatus(checklist) && 
                            getNotFiledStatus(checklist) &&
                            (<Button   className={'success-custom-cs'}
                                variant="outlined" 
                                sx={{ gap: '8px', color: '#FF7A00',borderColor:'#FF7A00',":hover":{borderColor:'#FF7A00'}}}
                                >
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.1175 4.10999C10.9425 4.10999 11.6175 3.43499 11.6175 2.60999C11.6175 1.78499 10.9425 1.10999 10.1175 1.10999C9.29248 1.10999 8.61748 1.78499 8.61748 2.60999C8.61748 3.43499 9.29248 4.10999 10.1175 4.10999ZM7.41748 14.535L8.16748 11.235L9.74248 12.735V17.235H11.2425V11.61L9.66748 10.11L10.1175 7.85999C11.0925 8.98499 12.5925 9.73499 14.2425 9.73499V8.23499C12.8175 8.23499 11.6175 7.48499 11.0175 6.43499L10.2675 5.23499C9.96748 4.78499 9.51748 4.48499 8.99248 4.48499C8.76748 4.48499 8.61748 4.55999 8.39248 4.55999L4.49248 6.20999V9.73499H5.99248V7.18499L7.34248 6.65999L6.14248 12.735L2.46748 11.985L2.16748 13.485L7.41748 14.535Z" fill="#FF7A00"/>
                                </svg>
                                PROCESSING
                            </Button>)}    
                            {checklist !== null &&
                            getNotStartedStatus(checklist) && 
                            (<Button
                            className={'success-custom-cs'}
                            variant="outlined"
                            sx={{ gap: '8px',  color: 'blue',width: '11rem' }} 
                            >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.27 4.5L9.57 6H13.5V10.5H10.98L10.68 9H5.25V4.5H9.27ZM10.5 3H3.75V15.75H5.25V10.5H9.45L9.75 12H15V4.5H10.8L10.5 3Z" fill="#0A2AE7"/>
                            </svg>
                                YET TO START
                            </Button>)}  
                            {checklist != null &&
                            getProessedStatus(checklist) &&
                            (<Button  
                            className={'success-custom-cs'}
                            variant="outlined" 
                            sx={{ gap: '8px', color: 'green', }}
                            color="success"
                            >
                             {ProcessIcon(true)}
                            </Button>)}
                            {(() => {
                                const states = [
                                    checklist?.affiliateTaxSummaryState,
                                    checklist?.assetSummaryState,
                                    checklist?.ecommerceState,
                                    checklist?.redRawMusicState,
                                    checklist?.redRawVideoState,
                                    checklist?.videoSummaryState,
                                    checklist?.claimsState,
                                    checklist?.shortsAdsState,
                                    checklist?.shortsSubsState,
                                    checklist?.channelState,
                                    checklist?.crmContractsStatus,
                                    checklist?.calculationsStatus
                                              ];

                                    const allStates = (value: number | undefined) => states.every((state) => state === value);
                                    const anyState = (value: number | undefined) => states.some((state) => state === value);

                                    switch (true) {
                                            case checklist === null || allStates(0):
                                                return <></>;
                                            case anyState(1):
                                                return <></>;
                                            case allStates(2):
                                                return <></>;
                                            case anyState(3):
                                                return <></>;
                                            case checklist?.calculationsStatus === 1:
                                                return <></>
                                            default:
                                               return <Button  
                                               className={'partial-custom-cs'}
                                               variant="outlined" 
                                               sx={{ gap: '8px', color: 'green', }}
                                               color="success">
                                                <FlakyOutlinedIcon sx={{color: 'green'}}/>
                                                Partiallay Processed
                                               </Button>;
                                            }
                                    })()}                  
                        </>
                    }
                        </Box>
                    </div>
            <div style={{justifyContent:'flex-end',alignItems:'flex-start',textAlign:'start',flex:0.7,display:'flex'}}>
            {tabValue === 0 ? 
            <Button onClick={refreshData} variant={'outlined'} size="small" sx={{mr: 2,mt:0.5}}>
              <RefreshOutlinedIcon />
            </Button>:
            <Button onClick={refreshEarlyPaymentData} variant={'outlined'} size="small" sx={{mr: 2}}>
            <RefreshOutlinedIcon />
            </Button>}
            <TextField
              label="Search"
              onChange={handleSearchChange}
              value={searchTerm}
              size="small"
              variant="standard"
              sx={{height:"34px", mr: 2, mt: '-10px'}}
              />
            {tabValue === 0 && <Button disabled={paymentSummaries.length === 0 || !paymentsLocked || loading || loadingPayments || !getProessedStatus(checklist)} variant={'contained'} onClick={onSubmitPayments} sx={{mr: 1}}>
              SUBMIT PAYMENTS
              {loadingPayments ? <SyncAltOutlined sx={{ml: 1}}/>: <></>}
            </Button>}
            {tabValue === 0 && <Button disabled={paymentSummaries.length === 0 || paymentsLocked || loading || !getProessedStatus(checklist)} variant={'contained'} onClick={lockUpPayment} sx={{mr: 1}} >
              LOCK PAYMENTS
            </Button>}
            {tabValue === 0 ? <Button disabled={paymentSummaries.length === 0 || loading || loadingPayments || isExportable(checklist)} variant={'contained'} onClick={exportData}>
              Export Data
              {loadingPayments ? <SyncAltOutlined sx={{ml: 1}}/>: <></>}
            </Button> :
            <Button disabled={earlyPayentSummaries.length === 0 || loadingPayments} variant={'contained'} onClick={exportEarlyPaymentData}>
              Export Data
            {loadingPayments ? <SyncAltOutlined sx={{ml: 1}}/>: <></>}
          </Button>}
          </div>
        </div>
        <>
        <Box sx={{borderBottom: 1, borderColor: 'divider', mt: 2}}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Final Payments" {...a11yProps(0)} />
                <Tab label="Early payments" {...a11yProps(1)} />
                <AdditionalPaymentDialog
                    open={open}
                    handleClose={handleClose}
                    kpeId={kpeId} creatorId={creatorId} creatorName={creatorName}
                    updateAdditionalPayments={updateAdditionalPayment}
                />
            </Tabs>            
        </Box>
        {tabComponent}            
        </>
            </Paper>
        </Box>
    )
}

export default CreatorSummary;