import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Dialog,
  TextField,
  Button,
  DialogActions,
  Box,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
interface EditRowModalProps {
  open: boolean;
  rowData: any;
  locked: boolean;
  onCancel: () => void;
}

interface LogsRowProps {
  label: string;
  oldValue: string | number;
  newValue: string | number;
}

const LogsRow: React.FC<LogsRowProps> = ({ label, oldValue, newValue }) => {
  if(oldValue === newValue) {
    return <></>;
  }
  return (
    <Box
      sx={{
        display: "flex",
        width: "400px",
        marginTop: "10px",
        alignItems: "center",
      }}
    >
      <Typography variant="subtitle2" sx={{ flex: 0.4 }}>
        <b>{label}</b>
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: "#FE0000",
          flex: 0.2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <b>{oldValue}</b>
      </Typography>
      <ArrowForwardIcon sx={{ marginRight: "10px", flex: 0.2 }} />
      <Typography
        variant="body2"
        sx={{
          color: "#01710D",
          flex: 0.2,
          display: "flex",
          justifyContent: "start",
        }}
      >
        <b>{newValue}</b>
      </Typography>
    </Box>
  );
};

const EditRowModal: React.FC<EditRowModalProps> = ({
  open,
  rowData,
  locked,
  onCancel,
}) => {
  return (
    <Dialog open={open} style={{ margin: "0 auto" }}>
      <DialogTitle>
        <Box
          sx={{
            display: "inline-block",
            marginLeft: 0,
            verticalAlign: "middle",
          }}
        >
          <p style={{ margin: "0", lineHeight: "1.2em", fontSize: "15px" }}>
            <b>Updated Logs</b>
          </p>
        </Box>
      </DialogTitle>
      <>
        <DialogContent>
          <Box sx={{ display: "flex" }}>
            <Typography variant="subtitle2" sx={{ marginBottom: "10px" }}>
              <b>{rowData.userName}</b> has updated the logs at{" "}
              <b>{new Date(rowData.timestamp).toLocaleDateString()}</b>
            </Typography>
          </Box>
          <Typography variant="subtitle2" sx={{ my: "10px" ,borderBottom:'1px solid rgba(224, 224, 224, 1)',color:'#FFC806' }}>
           <b>User</b>
          </Typography>
          <LogsRow
            label="Talent %"
            oldValue={rowData.talentRevShare.prev?.toFixed(4)}
            newValue={rowData.talentRevShare.current?.toFixed(4)}
          />
          <LogsRow
            label="Group %"
            oldValue={rowData.groupRevShare.prev?.toFixed(4)}
            newValue={rowData.groupRevShare.current?.toFixed(4)}
          />
          <LogsRow
              label="BP %"
              oldValue={rowData.bpRevShare.prev?.toFixed(4)}
              newValue={rowData.bpRevShare.current?.toFixed(4)}
          />
          <LogsRow
            label="Group Overage %"
            oldValue={rowData.groupOverageRevShare.prev?.toFixed(4)}
            newValue={rowData.groupOverageRevShare.current?.toFixed(4)}
          />
          <LogsRow
            label="BP Overage %"
            oldValue={rowData.bpOverageRevShare.prev?.toFixed(4)}
            newValue={rowData.bpOverageRevShare.current?.toFixed(4)}
          />
          <LogsRow
            label="Talent Overage %"
            oldValue={rowData.bpPaidMediaTalentOverage.prev?.toFixed(4)}
              newValue={rowData.bpPaidMediaTalentOverage.current?.toFixed(4)}
            />
          <LogsRow
            label="Wholesale Rate Card"
            oldValue={rowData.wholeSaleRateCard.prev?.toFixed(4)}
            newValue={rowData.wholeSaleRateCard.current?.toFixed(4)}
          />
          <LogsRow
            label={"Impressions"}
            oldValue={rowData.impressions.prev}
            newValue={rowData.impressions.current}
          />
          <LogsRow
          label="Rate ($)"
          oldValue={rowData.rate?.prev?.toFixed(4)}
          newValue={rowData.rate?.current?.toFixed(4)}
          />
          <Typography variant="subtitle2" sx={{ my: "12px" ,borderBottom:'1px solid rgba(224, 224, 224, 1)',color:'#73EC8B' }}>
           <b>System</b>
          </Typography>
          <LogsRow
            label={"Impressions / 1K"}
            oldValue={rowData.impressionsPerThousand.prev.toFixed(4)}
            newValue={rowData.impressionsPerThousand.current.toFixed(4)}
          />
          <LogsRow
            label="Gross Revenue"
            oldValue={rowData.grossRevenue.prev?.toFixed(4)}
            newValue={rowData.grossRevenue.current?.toFixed(4)}
          />
          <LogsRow
            label="Google (45%)"
            oldValue={rowData.googlePayment.prev?.toFixed(4)}
            newValue={rowData.googlePayment.current?.toFixed(4)}
          />
          <LogsRow
            label="Talent (55%)"
            oldValue={rowData.talentRevenue.prev?.toFixed(4)}
            newValue={rowData.talentRevenue.current?.toFixed(4)}
          />
          <LogsRow
            label="Gross Talent"
            oldValue={rowData.grossTalent.prev?.toFixed(4)}
            newValue={rowData.grossTalent.current?.toFixed(4)}
          />
          <LogsRow
            label="Gross Group"
            oldValue={rowData.grossGroup.prev?.toFixed(4)}
            newValue={rowData.grossGroup.current?.toFixed(4)}
          />
          <LogsRow
            label="Gross BP"
            oldValue={rowData.grossBentPixels.prev?.toFixed(4)}
            newValue={rowData.grossBentPixels.current?.toFixed(4)}
          />
          <LogsRow
            label="Overage CPM"
            oldValue={rowData.overageCpm.prev?.toFixed(4)}
            newValue={rowData.overageCpm.current?.toFixed(4)}
          />
          <LogsRow
            label="Overage $"
            oldValue={rowData.overageDollars.prev?.toFixed(4)}
            newValue={rowData.overageDollars.current?.toFixed(4)}
          />
          <LogsRow
            label="Residual Overage"
            oldValue={rowData.residualOverageDollars.prev?.toFixed(4)}
            newValue={rowData.residualOverageDollars.current?.toFixed(4)}
          />
          <LogsRow
            label="Talent Overage"
            oldValue={rowData.bpPaidMediaTalentOverageDollars.prev?.toFixed(4)}
            newValue={rowData.bpPaidMediaTalentOverageDollars.current?.toFixed(4)}
          />
          {/* 
          <LogsRow
            label="Service Fee %"
            oldValue={rowData.serviceFee.prev?.toFixed(2)}
            newValue={rowData.serviceFee.current?.toFixed(2)}
          /> */}
          <LogsRow
            label="Group Overage"
            oldValue={rowData.groupOverage?.prev?.toFixed(4)}
            newValue={rowData.groupOverage?.current?.toFixed(4)}
          />
          <LogsRow
            label="BP Overage"
            oldValue={rowData.bpOverage?.prev?.toFixed(4)}
            newValue={rowData.bpOverage?.current?.toFixed(4)}
          />
          <LogsRow
            label="BP Fees"
            oldValue={rowData.bpFees?.prev?.toFixed(4)}
            newValue={rowData.bpFees?.current?.toFixed(4)}
          />
          <LogsRow
            label="Tax Rate"
            oldValue={rowData.taxRate.prev?.toFixed(4)}
            newValue={rowData.taxRate.current?.toFixed(4)}
          />
          <LogsRow
            label="US Tax W/H"
            oldValue={rowData.tax.prev?.toFixed(4)}
            newValue={rowData.tax.current?.toFixed(4)}
          />
          <LogsRow
            label="Due to Talent"
            oldValue={rowData.dueToTalent.prev?.toFixed(4)}
            newValue={rowData.dueToTalent.current?.toFixed(4)}
          />
          <LogsRow
            label="Due to Group"
            oldValue={rowData.dueToGroup.prev?.toFixed(4)}
            newValue={rowData.dueToGroup.current?.toFixed(4)}
          />
          <LogsRow
            label="Due to Company"
            oldValue={rowData.dueToCompany.prev?.toFixed(4)}
            newValue={rowData.dueToCompany.current?.toFixed(4)}
          />
          <LogsRow
            label="Due to BP US"
            oldValue={rowData.dueTobpus.prev?.toFixed(4)}
            newValue={rowData.dueTobpus.current?.toFixed(4)}
          /> 
          {/* 
          */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} sx={{ width: "100px" }}>
            Cancel
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default EditRowModal;
