import {GridColumns, GridRenderCellParams, GridValueFormatterParams} from "@mui/x-data-grid";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const roundDecimals = (params: number) => {
    if (params == null) {
        return '';
    }
    return params.toFixed(4);
}
const getPercentage = (params: number) => {
    if (params == null) {
        return '';
    }
    return (params * 100).toFixed(4);
}

export const gamTableColumns: GridColumns = [
    {
        field: "error", headerName: "", width: 50, renderCell: (params: GridRenderCellParams<boolean>) => (
            <>
                {params.value ? <ErrorOutlineIcon sx={{color: 'red'}}/> : <></>}
            </>
        ),
        headerAlign: 'center', align: 'center', headerClassName: 'col-header'
    },
    {
      field: "userName",
      headerName: "User",
      width: 100,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'col-header'
    },
    {
      field: "timestamp",
      headerName: "DateTime",
      width: 200,
     renderCell: (params) => (
      <>
        {new Date(params.value).toLocaleDateString()} {new Date(params.value).toLocaleTimeString()}
      </>
    ),
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'col-header'
    },
    {
        field: "advertiserTitle",
        headerName: "Advertiser",
        width: 200,
        headerAlign: 'left',
        align: 'left',
        headerClassName: 'col-header'
    },
    {
      field: "countryCode",
      headerName: "Country Code",
      renderCell: (params) => (
          <>
            {params.value ? params.value : '--'}
          </>
        ),
      width: 200,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'col-header'
    },
    {
      field: "assetLabel",
      headerName: "Asset Label",
      width: 200,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'col-header'
    },
    {
        field: "lineItemTitle",
        headerName: "Line Item",
        width: 200,
        headerAlign: 'left',
        align: 'left',
        headerClassName: 'col-header'
    },
    {
        field: "orderTitle",
        headerName: "Order",
        width: 200,
        headerAlign: 'left',
        align: 'left',
        headerClassName: 'col-header'
    },
    {
        field: "groupName",
        headerName: "Group",
        width: 200,
        headerAlign: 'left',
        align: 'left',
        headerClassName: 'col-header'
    },
    {
        field: "ytChannelKpeId",
        headerName: "Youtube: YT Channel ID (kpeid)",
        width: 250,
        headerAlign: 'left',
        align: 'left',
        headerClassName: 'col-header'
    },
    {
        field: "ytChannelId",
        headerName: "YT Channel ID",
        width: 200,
        headerAlign: 'left',
        align: 'left',
        headerClassName: 'col-header'
    },
    {
        field: "rate",  
        headerName: "Rate ($)",
        renderCell: (params) => (
           params.value.prev === params.value.current ?
           <>
           {params.value.current.toFixed(4)}
           </>
           : <span
              style={{
                backgroundColor:'#FFC806',
                padding:'10px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
            }}
            >
              {params.value.prev.toFixed(4)}
              <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
              {params.value.current.toFixed(4)} 
            </span>
          ),
        width: 150,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "impressions",
        headerName: "Impressions",
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {params.value.current.toFixed(4)}
            </>
            : <span
               style={{
                 backgroundColor:'#FFC806',
                 padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {params.value.prev.toFixed(4)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {params.value.current.toFixed(4)} 
             </span>
         ),
        width: 150,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "impressionsPerThousand",
        headerName: "Impressions / 1k",
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {params.value.current.toFixed(4)}
            </>
            : <span
               style={{
                 backgroundColor:'#73EC8B',
                 padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {params.value.prev.toFixed(4)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {params.value.current.toFixed(4)} 
             </span>
         ),
        width: 150,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
      field: "anticipatedRateCard",
      headerName: "Anticipated Rate Card",
      width: 200,
      renderCell: (params) => (
        <>
        {roundDecimals(params.value)}
        </>
      ),
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'col-header'
    },
    {
        field: "wholeSaleRateCard",
        headerName: "Wholesale Rate Card",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "talentRevShare",
        headerName: "Talent %",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {getPercentage(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {getPercentage(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {getPercentage(params.value.current)} 
             </span>
         ),
        // valueFormatter: getPercentage,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "groupRevShare",
        headerName: "Group %",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {getPercentage(params.value.current)}
            </>
            : <span
               style={{
                 backgroundColor:'#FFC806',
                 padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {getPercentage(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {getPercentage(params.value.current)} 
             </span>
         ),
        // valueFormatter: getPercentage,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "bpRevShare",
        headerName: "BP %",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {getPercentage(params.value.current)}
            </>
            : <span
               style={{
                 backgroundColor:'#FFC806',
                 padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {getPercentage(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {getPercentage(params.value.current)} 
             </span>
         ),
        // valueFormatter: getPercentage,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "grossRevenue",
        headerName: "Gross Revenue",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "googlePayment",
        headerName: "Google (45%)",
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        width: 150,
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "talentRevenue",
        headerName: "Talent (55%)",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "grossTalent",
        headerName: "Gross Talent",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "grossGroup",
        headerName: "Gross Group",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "grossBentPixels",
        headerName: "Gross BP",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "overageCpm",
        headerName: "Overage CPM",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "overageDollars",
        headerName: "Overage $",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "bpPaidMediaTalentOverage",
        headerName: "Talent Overage %",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {getPercentage(params.value.current)}
            </>
            : <span
               style={{
                 backgroundColor:'#FFC806',
                 padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {getPercentage(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {getPercentage(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "bpPaidMediaTalentOverageDollars",
        headerName: "Talent Overage",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "residualOverageDollars",
        headerName: "Residual Overage",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "cpm",
        headerName: "Service Fee %",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {getPercentage(params.value.current)}
            </>
            : <span
               style={{
                 backgroundColor:'#FFC806',
                 padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {getPercentage(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {getPercentage(params.value.current)} 
             </span>
         ),
        // valueFormatter: getPercentage,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "groupOverageRevShare",
        headerName: "Group Overage %",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {getPercentage(params.value.current)}
            </>
            : <span
               style={{
                 backgroundColor:'#FFC806',
                 padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {getPercentage(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {getPercentage(params.value.current)} 
             </span>
         ),
        // valueFormatter: getPercentage,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "bpOverageRevShare",
        headerName: "BP Overage %",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {getPercentage(params.value.current)}
            </>
            : <span
               style={{
                 backgroundColor:'#FFC806',
                 padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {getPercentage(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {getPercentage(params.value.current)} 
             </span>
         ),
        // valueFormatter: getPercentage,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "groupOverage",
        headerName: "Group Overage",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "bpOverage",
        headerName: "BP Overage",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },

    {
        field: "bpFees",
        headerName: "BP Fees",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "taxRate",
        headerName: "Tax Rate",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {getPercentage(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {getPercentage(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {getPercentage(params.value.current)} 
             </span>
         ),
        // valueFormatter: getPercentage,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "tax",
        headerName: "US Tax W/H",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "dueToTalent",
        headerName: "Due to Talent",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "dueToGroup",
        headerName: "Due to Group",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "dueToCompany",
        headerName: "Due to Company",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "dueTobpus",
        headerName: "Due to BP US",
        width: 150,
        renderCell: (params) => (
            params.value.prev === params.value.current ?
            <>
            {roundDecimals(params.value.current)}
            </>
            : <span
               style={{
                backgroundColor:'#73EC8B',
                padding:'10px',
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center',
             }}
             >
               {roundDecimals(params.value.prev)}
               <ArrowForwardIcon sx={{fontSize:'18px',mx:1}}/>
               {roundDecimals(params.value.current)} 
             </span>
         ),
        // valueFormatter: roundDecimals,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {
        field: "errorMessage",
        headerName: "Error Message",
        width: 350,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'col-header'
    },
    {field: "gamOrderId", hide: true},
    {field: "lineItemId", hide: true},
    {field: "reportId", hide: true},
]